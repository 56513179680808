import React, { Component } from 'react'
import Layout from '../../../components/layout';
import { Banner } from '../../../components/banner';
import moment from 'moment';
import { Cache } from "aws-amplify";
import { navigate } from '@reach/router'
import { FormGroup, Col, Row, Button } from 'reactstrap'
import Query from "./../../../containers/netsclick/query"
import { ENV_PROD, ENV_UAT } from '../../../utils/constants'
import { getSDKList, downloadObject, updateIntegrationValues } from '../../../services/api'
import { isLoggedIn } from '../../../services/auth';
import Merchantcard from '../../../containers/netsclick/merchantcard';

export default class Material extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetched: true,
            sdkList: null,
            selectedEnv: ENV_UAT,
            isToggled: true
        }
    }
    handleToggle() {
        this.setState({
            isToggled: !this.state.isToggled
        })
    }
    async UNSAFE_componentWillMount() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
        if (Cache.getItem("ma") != 1) {
            navigate(`/account/mfauth/`, { state: { netsClick: true } })
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
        let sdkInfo = null
        sdkInfo = await getSDKList(this.state.selectedEnv)

        this.setState({
            sdkList: sdkInfo,
        })
    }
    async componentDidMount() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }

    }
    async changeEnv(value) {
        let data = null
        this.setState({
            selectedEnv: value,
            sdkList: data,
            loader: true,
        }, async function () {
            data = await getSDKList(value)

            this.setState({
                sdkList: data,
                loader: false
            })
        })
    }

    async downloadFile(e, key, filename) {
        const application = isLoggedIn() ? JSON.parse(window.localStorage.getItem('ncApp')) : null;
        if (application) {
           
            let inputObj = {
                type: '2',
                email: application.email,
                netsclickAppID: application.nc_app_id

            }
            await downloadObject(key, filename)
            await updateIntegrationValues(inputObj)
        }

    }
    redirectPage() {
        navigate(`/product/netsclick/materials`)
        if (typeof window !== "undefined") {
            window.location.reload()
        }
    }
    render() {
        let { fetched, sdkList, selectedEnv } = this.state
        let i = 0, j = 0
        if (!fetched) {
            return <div class="loading"></div>
        }
        return (
            <Layout>
                <Banner title={`NETS Click`} />
                <div className='leftAligned'>
                    <FormGroup row>
                        <Col sm={9}>
                            <h4><b>Welcome to NETS Click developer portal page.</b></h4>
                        </Col>
                        <Merchantcard />
                    </FormGroup>
                    <div>
                        <h4 onClick={e => this.redirectPage()} className='topAligned'><a href='#'> {`< Back`}</a></h4>
                        <h2 className='topAligned'><b>NETS Click Integration - SDK & Integration Specs </b></h2>
                        <div className='leftAlignedMedium topAlignedMedium'>
                            <Button className='highWidth' color={selectedEnv == ENV_UAT ? 'primary' : 'link'} size="lg" onClick={e => this.changeEnv(ENV_UAT)}>UAT</Button>{' '}
                            <Button className='highWidth' color={selectedEnv == ENV_PROD ? 'primary' : 'link'} size="lg" onClick={e => this.changeEnv(ENV_PROD)}>PROD</Button>
                        </div>
                        <Row className='w-75 '>
                            <Col>
                                <table class="tempIntSetting leftAligned topAligned bottonAligned">
                                    <thead>
                                        <tr><th colspan="4">
                                            <h5><b>Android</b></h5></th></tr>
                                        <tr>
                                            <th>File Name</th>
                                            <th>Version</th>
                                            <th>Uploaded Date</th>
                                            <th>Comments</th>
                                        </tr>
                                    </thead>
                                    {sdkList && sdkList.length > 0 ? <tbody>
                                        { sdkList ? sdkList.map(row => {
                                            if (row && row.OS_TYPE == 'A' && i < 5) {
                                                i++
                                                return (
                                                    <tr>
                                                        <td onClick={e => this.downloadFile(e, row.SDK_FILE_PATH, row.SDK_FILE_NAME)}><a href='#'>{row.SDK_FILE_NAME}</a></td>
                                                        <td>{row.SDK_VERSION}</td>
                                                        <td>{moment(row.UPLOADED_AT).format('DD/MM/YYYY')}</td>
                                                        <td>{row.comments}</td>
                                                    </tr>
                                                )
                                            }
                                        }) : ''}
                                    </tbody> : <tbody><tr><div className='centered'>No SDK reference added</div></tr></tbody>}
                                </table>
                            </Col>
                            <Col>
                                <table class="tempIntSetting leftAligned topAligned bottonAligned">
                                    <thead>
                                        <tr><th colspan="4">
                                            <h5><b>IOS</b></h5></th></tr>
                                        <tr>
                                            <th>File Name</th>
                                            <th>Version</th>
                                            <th>Uploaded Date</th>
                                            <th>Comments</th>
                                        </tr>
                                    </thead>
                                    {sdkList && sdkList.length > 0 ? <tbody>
                                        { sdkList ? sdkList.map(row => {
                                            if (row && row.OS_TYPE == 'I' && j < 5) {
                                                j++
                                                return (
                                                    <tr>
                                                        <td onClick={e => this.downloadFile(e, row.SDK_FILE_PATH, row.SDK_FILE_NAME)}><a href='#'>{row.SDK_FILE_NAME}</a></td>
                                                        <td>{row.SDK_VERSION}</td>
                                                        <td>{moment(row.UPLOADED_AT).format('DD/MM/YYYY')}</td>
                                                        <td>{row.comments}</td>
                                                    </tr>
                                                )
                                            }
                                        }) : ''}
                                    </tbody> : <tbody><tr><div className='centered'>No SDK reference added</div></tr></tbody>}
                                </table>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className='topAlignedMedium'/>
                <Query />
            </Layout>
        )
    }
}
